import React from 'react';
import { Subject } from 'rxjs';
import { startWith, scan } from 'rxjs/operators';
import reducer from './reducer';
import { Observable } from 'rxjs/Observable';
import { map } from 'lodash';

const isObservable = obs => obs instanceof Observable;

const initState = { objects: [], loading: false, primaryCategory: undefined, subCategory: undefined, firebaseInitialized: false, editObject: undefined, inlines: undefined };
const action$ = new Subject();

const createStore = () => action$.pipe(startWith(initState), scan(reducer));

const actionCreator = () =>  {
  return new Promise((resolve, reject) => resolve(action$));
}

const getStore = () => action$;

export {
  createStore,
  actionCreator,
  isObservable,
  getStore,
};