/* eslint-disable react/prop-types */
// import { Observable } from 'rxjs/Observable';
import React, { createContext, useContext } from 'react';
// import '../../styles/Common/toast.scss';

export const log = console.log.bind(console);
export const StateContext = createContext();
// const MyCustomToast = ({ appearance, children, onDismiss }) => (
//   <div onClick={() => onDismiss()} className={`${appearance === 'error' ? 'toastError' : 'toastSuccess'} toast`}>
//     <div className="sideBar" />
//     <p>{children}</p>
//   </div>
// );
export const StateProvider = ({ state, children }) => (
  <StateContext.Provider value={state}>
    {/* <ToastProvider autoDismissTimeout={10000} components={{ Toast: MyCustomToast }}> */}
    {children}
    {/* </ToastProvider> */}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);
