import _ from 'lodash';
// import { checkInline } from './actionTypes/inlineTypes';
// import { checkCategories } from './actionTypes/categoryTypes';
// import { checkFieldTypes } from './actionTypes/fieldTypes';

const reducer = (state = null, action) => {
  if (action !== undefined || action !== null) {
    // console.log(action.type);
    // const inlineResponse = checkInline(state, action);
    // if (inlineResponse !== undefined) {
    //   return inlineResponse;
    // }
    // const categoriesResponse = checkCategories(state, action);
    // if (categoriesResponse !== undefined) {
    //   return categoriesResponse;
    // }
    // const fieldResponse = checkFieldTypes(state, action);
    // if (fieldResponse !== undefined) {
    //   return fieldResponse;
    // }
    // switch (action.type) {
    //   case 'EDIT_POST_DATA':
    //     console.log(state.editObject);
    //     console.log(action.payload);

    //     return {
    //       ...state,
    //       editObject: _.merge(state.editObject, action.payload),
    //       // editObject: {
    //       //   ...state.editObject,
    //       //   ...action.payload,
    //       // },
    //     };
    //   case 'FETCH_PRODUCTS_SUCCESS':
    //     return {
    //       ...state,
    //       objects: action.payload,
    //       loading: false,
    //     };
    //   case 'EDIT_PRODUCT_SUCCESS':
    //     return {
    //       ...state,
    //       editObject: action.payload,
    //       metafields: action.metafields,
    //       loading: false,
    //     };
    //   case 'REQUEST_LOADING':
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   case 'REQUEST_FAILURE':
    //     return {
    //       ...state,
    //       error: action.payload,
    //     };
    //   default: return state;
    // }
  }
};

export default reducer;
