/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import ReactDOM from 'react-dom';
import React from 'react';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/startWith';
import 'typeface-poppins';
import { createStore } from './src/Rx/store';
import { StateProvider } from './src/Rx/utils/utils';
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

export const replaceHydrateFunction = () => (element, container, callback) => {
  createStore()
    .subscribe((state) => {
      ReactDOM.render(<StateProvider state={state}>{element}</StateProvider>, container, callback);
    });
};
